import { use100vh } from "react-div-100vh";
import { BsInfoCircle, BsChatText, BsMap } from "react-icons/bs";
import { CgProfile } from "react-icons/cg";
import { MdOutlineAddLocationAlt } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import Logo from "../assets/logo_512px.png";

interface IProps {
    toggleDisplayNavigation: React.Dispatch<React.SetStateAction<boolean>>;
    setIsAddingSpot: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function Navigation({toggleDisplayNavigation, setIsAddingSpot}: IProps) {
    const navigate = useNavigate();
    
    // Standard height is defined in CSS. This overwrites height to fit mobile devices.
    // https://www.npmjs.com/package/react-div-100vh
    const browserHeight = use100vh();
    let navigationHeight: number | undefined = undefined;
    if (browserHeight) {
        navigationHeight = browserHeight - 50;
    };
    
    function activateAddSpotFlow(): void {
        navigate("/");
        setIsAddingSpot(true);
        toggleDisplayNavigation(false);
    }

    function toProfile(): void {
        navigate("/user-profile");
        toggleDisplayNavigation(false);
    }

    function toAbout(): void {
        navigate("/about");
        toggleDisplayNavigation(false);
    }

    function toReachOut(): void {
        navigate("/contact");
        toggleDisplayNavigation(false);
    }

    function toMap(): void {
        navigate("/");
        toggleDisplayNavigation(false);
    }
    
    return(
        <div className="navigation" style={{height: navigationHeight}}>
            
            <div className="menuBox">
                <ul>
                    <li>
                        <button onClick={toMap} className="menuButton">
                            <BsMap className='menuBoxIcon'/>
                            <span className="menuBoxText">Back to the map</span>
                        </button>
                    </li>
                </ul>
            </div>
            
            <div className="menuBox">
                <ul>
                    <li>
                        <button onClick={activateAddSpotFlow} className="menuButton">
                            <MdOutlineAddLocationAlt className='menuBoxIcon'/>
                            <span className="menuBoxText">Contribute a spot to the map</span>
                        </button>
                    </li>
                    <li>
                        <button onClick={toProfile} className="menuButton">
                            <CgProfile className="menuBoxIcon" />
                            <span className="menuBoxText">User profile</span>
                        </button>
                    </li>
                </ul>
            </div>

            <div className="menuBox">
                <ul>
                    <li>
                        <button onClick={toAbout} className="menuButton">
                            <BsInfoCircle className="menuBoxIcon" />
                            <span className="menuBoxText">About Sunsetspots.ch</span>
                        </button>
                    </li>
                    <li>
                        <button onClick={toReachOut} className="menuButton">
                            <BsChatText className="menuBoxIcon" />
                            <span className="menuBoxText">Get in contact</span>
                        </button>
                    </li>
                </ul>
            </div>

            <div className="navigationFooter">
                <br /><br />
                <img src={Logo} className="logo" alt="Logo of Sunsetspots.ch" />
                <p className="h3ReducedMargin">2023 © Sunsetspots.ch</p>
            </div>
            
        </div>
    );
}