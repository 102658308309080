import { getDoc, getFirestore } from "@firebase/firestore";
import { collection, getDocs, addDoc, doc, Timestamp } from "firebase/firestore";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";

import { app } from './firebase-config';
import { SpotCategory } from "./types/SpotCategory";

const db = getFirestore(app); // Initialize Firestore db
const spotsCollectionRef = collection(db, "spots-collection"); // Get reference to the spots-collection on firebase.
const storage = getStorage(app); // Firebase storage to save images of users.

/** Definition of the spot object getting saved to spots-collection on firebase. */
export type spotObj = {
  title: string;
  description: string;
  categories: SpotCategory[];
  lat: number;
  lng: number;
  userId: string;
  timestamp: Timestamp;
}

/** Function accesses database and returns all saved spots. Async because of db access. */
export async function getSpotsFromDb() {
  const spotsCollection = await getDocs(spotsCollectionRef); // this returns whole collection incl. metadata.
  const finalSpotsCollection: {id: string; title: string; lat: number; lng: number;}[] = []; // final return type.

  // iterate over docs in collection, put doc together as object and save in finalSpotsCollection:
  spotsCollection.docs.forEach((doc) => {
    const id = doc.id;
    const title = doc.data().title;
    const lat = doc.data().lat;
    const lng = doc.data().lng;

    const obj = {id: id, title: title, lat: lat, lng: lng};
    finalSpotsCollection.push(obj);
  });

  return(finalSpotsCollection);
}

/** takes spotObj and persists it to spots-collection on db. Returns id of newly created doc. */
export async function addSpotToDb(spotObj: spotObj) {
  const docRef = await addDoc(spotsCollectionRef, spotObj);
  return (docRef.id);
}

/** Takes an id of a spot and returns information about it. */
export async function getSpotInfoById(spotId: string) {
  const spotInfo = {} as {title: string, description: string, categories: SpotCategory[], lat: number, lng: number}; // Define here what should be returned.

  const docRef = doc(db, "spots-collection", spotId);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    spotInfo.title = docSnap.data().title;
    spotInfo.description = docSnap.data().description;
    spotInfo.categories = docSnap.data().categories as SpotCategory[];
    spotInfo.lat = docSnap.data().lat;
    spotInfo.lng = docSnap.data().lng;
  } else {
    console.log("No spot with this id exists on database.")
  }

  return(spotInfo);
}

/** Uploades an image to Firebase Cloud Storage. */
export async function uploadImage(filename: string, image: File) {
  const imageRef = ref(storage, filename);
  uploadBytes(imageRef, image);
}

/** Provide function with id of spot, then it will return the url to it. */
export async function getImageUrlById(id: string) {
  try {
    const url = await getDownloadURL(ref(storage, id));
    return url;
  }
  catch (error) {
    return undefined;
  }
}

/** Creates a new document in reported-spots with the id of the reported spot */
export async function addReportedSpotToDb(spotId: string, reason: string) {
  const reportedSpotsRef = collection(db, "reported-spots");
  await addDoc(reportedSpotsRef, {id: spotId, reason: reason});
}
