import { createUserWithEmailAndPassword, getAuth, GoogleAuthProvider, sendEmailVerification, sendPasswordResetEmail, signInWithEmailAndPassword, signInWithPopup, signOut, AuthError, deleteUser } from 'firebase/auth';

import { app } from './firebase-config';

// Firebase auth documentation: https://firebase.google.com/docs/auth/web/password-auth?authuser=0
// Guide explaining setting everything up: https://blog.logrocket.com/user-authentication-firebase-react-apps/

export const auth = getAuth(app); // initializing authentication component.



export async function signInWithGoogle(): Promise<string> {
    // Sign in using a popup
    const  googleProvider = new GoogleAuthProvider();
    googleProvider.addScope('profile');
    googleProvider.addScope('email');
    try {
      await signInWithPopup(auth, googleProvider);
    } catch (err) {
      const error = err as AuthError;
      if (error.code === "auth/account-exists-with-different-credential") {
        // TODO: This is a more complex error. Where the error needs to decide which linked provider to use. For now, just return the error message.
        return "An account already exists with the same email address but different sign-in credentials. Sign in using a provider associated with this email address.";
    }
      if (error.code === "auth/auth-domain-config-required") { return "An auth domain configuration is required."; }
      if (error.code === "auth/cancelled-popup-request") { return "Only one popup request is allowed at a time."; }
      if (error.code === "auth/operation-not-allowed") { return "Google sign-in is not enabled."; }
      if (error.code === "auth/operation-not-supported-in-this-environment") { return "This operation is not supported in the environment this application is running on. \"location.protocol\" must be http or https."; }
      if (error.code === "auth/popup-blocked"){ return "Popup was blocked by the browser."; }
      if (error.code === "auth/popup-closed-by-user") { return "Popup was closed by user."; }
      if (error.code === "auth/unauthorized-domain") { return "The domain of the URL is not whitelisted. Whitelist the domain in the Firebase console."; }
      else { return "An unknown error occured. Please try again."; }
    }
    return "success";
}

export async function logInWithEmailAndPassword(email: string, password: string): Promise<string> {
    try {
        await signInWithEmailAndPassword(auth, email, password);
        return "success";
    } catch (err) { // Error-message documentation: https://firebase.google.com/docs/reference/js/v8/firebase.auth.Auth#signinwithemailandpassword
        const error = err as AuthError;
        if (error.code === "auth/user-not-found") { return "No user found with this email address."; }
        if (error.code === "auth/wrong-password") { return "Invalid password."; }
        if (error.code === "auth/invalid-email") { return "Invalid email address."; }
        else { return "An unknown error occured. Please try again."; }
    }
}

export async function registerWithEmailAndPassword(email: string, password: string): Promise<string> {
    try {
        await createUserWithEmailAndPassword(auth, email, password);
        // Here it also would be possible to enter user to db (new collection) to enable saving a username etc.
        return "success";
    } catch (err) {
        console.error(err);
        const error = err as AuthError;
        if (error.code === "auth/email-already-in-use") { return "There already exists an account with the given email address."; }
        if (error.code === "auth/invalid-email") { return "The email address you provided is invalid."; }
        if (error.code === "auth/weak-password") { return "This password is too weak. Please choose a stronger one."; }
        else { return "An unknown error occured. Please try again."; }
    }
}

export function logOut() {
    signOut(auth);
}

export function sendMailVerification() {
    if (auth.currentUser) sendEmailVerification(auth.currentUser);
}

export function resetPassword(email: string) {
    sendPasswordResetEmail(auth, email);
}

/** Deletes a user account out of firebase authentication. Returns either "success" or error message. */
export async function deleteAccount(): Promise<string> {
    if(!auth.currentUser) { return "Error: No logged in user was found."; }
    try {
        await deleteUser(auth.currentUser);
        return "success";
    } catch (err) {
        return "Error: Something went wrong. Please try again.";
    }
}
