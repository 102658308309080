import { onAuthStateChanged } from "firebase/auth";
import { useEffect, useState } from "react";
import { BsXLg } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import { auth, sendMailVerification } from "../authHandler";

export default function VerifyMail() {
    const [userMail, setUserMail] = useState<string|null>(null);
    const [mailSent, setMailSent] = useState<string>();
    const navigate = useNavigate();

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                setUserMail(user.email);
                if(user.emailVerified) {
                    navigate("/");
                }
            } else {
                navigate("/"); // If no user is logged in, navigate to main page.
            }
        });
    }, [navigate]);
    
    function sendMail() {
        sendMailVerification();
        setMailSent("A verification mail was sent to your mailbox. Make sure to look in your spam folder if you cannot find it.")
    }

    return (
        <div className="formBox">
            <div className="closeFormBox">
                <Link to="/"><BsXLg className='closeCross' /></Link>
            </div>
            <br />
            <h3>E-Mail Address Verification</h3>
            <p>Hi, {userMail}</p>
            <p>Please verify your e-mail address such that you are able to contribute to Sunsetspots.ch</p>
            <button className="textButton" onClick={ sendMail }>Send Verification Mail</button>
            <p>{mailSent}</p>
        </div>
    );
}