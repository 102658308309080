export enum SpotCategory {
  Bench = "bench",
  Fireplace = "fireplace",
  Restaurant = "restaurant",
  Summit = "summit",
  Tower = "tower",
}

export const spotCategoryToIconMapping = new Map<SpotCategory, string>([
  [SpotCategory.Bench, "🪑 Bench"],
  [SpotCategory.Fireplace, "🔥 Fireplace"],
  [SpotCategory.Restaurant, "🍺 Restaurant"],
  [SpotCategory.Summit, "🏔 Summit"],
  [SpotCategory.Tower, "🗼 Tower"],
]);
