import { SpotCategory, spotCategoryToIconMapping } from "../types/SpotCategory";

interface IProps {
  selectedCategories: SpotCategory[];
  onCategoryClick: (category: SpotCategory) => void;
  isEditable: boolean;
}

export default function SpotCategorySelection({
  selectedCategories,
  onCategoryClick,
  isEditable,
}: IProps) {
  function isSelected(category: SpotCategory): boolean {
    return selectedCategories.some((selectedCat) => category === selectedCat);
  }

  const shownCategories = isEditable
    ? Object.values(SpotCategory)
    : selectedCategories;

  return (
    <div className="spotCategoryContainer">
      {shownCategories.map((cat) => (
        <div
          className={`spotCategory ${
            isSelected(cat) ? "selected" : undefined
          } ${isEditable ? "editable" : undefined}`}
          key={cat}
          onClick={() => onCategoryClick(cat)}
        >
          <span>{spotCategoryToIconMapping.get(cat)}</span>
        </div>
      ))}
    </div>
  );
}
