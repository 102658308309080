import { useEffect, useState } from 'react';
import { onAuthStateChanged, User } from 'firebase/auth';
import { auth } from './authHandler';
import { Route, Routes, useLocation } from 'react-router-dom';
import './styles/App.css';
import 'leaflet/dist/leaflet.css';

import MenuBar from './components/MenuBar';
import Navigation from './components/Navigation';
import Login from './routes/Login';
import UserProfile from './routes/UserProfile';
import EmptyPage from './routes/EmptyPage';
import CreateAccount from './routes/CreateAccount';
import VerifyMail from './routes/VerifyMail';
import Home from './components/Home';
import ResetPassword from './routes/ResetPassword';
import About from './routes/About';
import Contact from './routes/Contact';
import DeleteAccount from './routes/DeleteAccount';

export default function App() {
  const [displayNavigation, toggleDisplayNavigation] = useState(false);

  // state for adding new spots:
  const [isAddingSpot, setIsAddingSpot] = useState(false);

  // state for authentication:
  const [user, setUser] = useState<User|null>(null);
  const [mailVerified, setMailVerified] = useState(false);

  // used later down to only display MenuBar if not login page etc. https://stackoverflow.com/questions/63484080/how-to-hide-the-navbar-from-the-login-page-with-react-router
  const { pathname } = useLocation();

  // useEffect is needed, because if a state gets updated the component rerenders, which reexecutes onAuthStateChanged -> infinite loop.
  // https://stackoverflow.com/questions/61155701/how-to-prevent-infinite-loop-caused-by-onauthstatechanged-firebase-auth
  useEffect(() => {
    // Listener waits until changes on "auth" are done, then updates app state about user and e-mail verification.
    onAuthStateChanged(auth, (user) => {
      if (user) {
        user.getIdToken(true) // force a refresh of token from backend. Without cannot add spots even though e-mail verification is done. https://stackoverflow.com/questions/60996671/firestore-check-if-the-logged-in-user-verified-his-email
        setUser(user);
        setMailVerified(user.emailVerified);
      } else {
        setUser(null);
        setMailVerified(false);
     }
   });
  }, []);

  return(
    <div className='App'>
      { pathname !== "/login" && pathname !== "/create-account" && pathname !=="/delete-account" && pathname !== "/verify-mail" && pathname !== "/reset-password" && (
      <div className="menuBar">
        <MenuBar
          displayNavigation={displayNavigation}
          toggleDisplayNavigation={toggleDisplayNavigation}
          user={user} />
      </div>
      )}
      <div className="site">

        {displayNavigation && (<Navigation
            toggleDisplayNavigation={toggleDisplayNavigation}
            setIsAddingSpot={setIsAddingSpot} />)}

        <Routes>
          <Route path="/" element={<Home isAddingSpot={isAddingSpot} setIsAddingSpot={setIsAddingSpot} user={user} mailVerified={mailVerified} />} />
          <Route path="/user-profile" element={<UserProfile user={user} />} />
          <Route path="*" element={<EmptyPage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/create-account" element={<CreateAccount />} />
          <Route path="/verify-mail" element={<VerifyMail />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/delete-account" element={<DeleteAccount />} />
        </Routes>

      </div>
    </div>
  );
}
