import { GeoSearchControl, OpenStreetMapProvider } from "leaflet-geosearch";
import { useEffect } from "react";
import { useMap } from "react-leaflet";

// Leaflet geosearch documentation: https://github.com/smeijer/leaflet-geosearch

import 'leaflet-geosearch/assets/css/leaflet.css' // Import is needed: https://github.com/smeijer/leaflet-geosearch/issues/113

export default function SearchBar() {
    const map = useMap();
    useEffect(() => {
        // searchControl initialization is like that because of TS: https://bobbyhadz.com/blog/typescript-new-expression-whose-target-lacks-construct-signature
        const searchControl = new (GeoSearchControl as any)({
            provider: new OpenStreetMapProvider(),
            style: 'bar',
            showMarker: false,
            notFoundMessage: 'Sorry, that location could not be found.',
            searchLabel: 'Search for a location'
        });
        map.addControl(searchControl);
        return () => {map.removeControl(searchControl);} // this prevents duplicated searchbars when rerendering.
    });
    
    return null; // component does not return anything.
}