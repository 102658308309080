import { User } from "firebase/auth";
import { latLng, LatLng } from "leaflet";
import { useState } from "react";
import { use100vh } from "react-div-100vh";

import AddSpotFlow from "./AddSpotFlow";
import Map from "./Map";
import SpotInfoBox from "./SpotInfoBox";

interface IProps {
    isAddingSpot: boolean;
    setIsAddingSpot: React.Dispatch<React.SetStateAction<boolean>>;
    user: User | null;
    mailVerified: boolean;
}

export default function Home({isAddingSpot, setIsAddingSpot, user, mailVerified}: IProps) {
    const [clickResultsInMarker, toggleClickResultsInMarker] = useState(false);
    const [newSpotCoord, addNewSpotCoord] = useState<LatLng>(latLng(0,0));
    const [spotInFocus, setSpotInFocus] = useState<string>();

    // Standard height is defined in CSS. This overwrites height to fit mobile devices.
    // https://www.npmjs.com/package/react-div-100vh
    const browserHeight = use100vh();
    let navigationHeight: number | undefined = undefined;
    if (browserHeight) {
        navigationHeight = browserHeight - 50;
    };
    
    return(
        <>
            {isAddingSpot && (<AddSpotFlow 
                setIsAddingSpot={setIsAddingSpot}
                user={user}
                mailVerified={mailVerified}
                toggleClickResultsInMarker={toggleClickResultsInMarker}
                addNewSpotCoord={addNewSpotCoord}
                newSpotCoord={newSpotCoord} />)}
            
            {spotInFocus && <SpotInfoBox
                spotInFocus={spotInFocus}
                setSpotInFocus={setSpotInFocus} />}

            <div className="mapPane" style={{height: navigationHeight}}>
                <Map
                    clicksResultInMarker={clickResultsInMarker}
                    addNewSpotCoord={addNewSpotCoord}
                    newSpotCoord={newSpotCoord}
                    spotInFocus={spotInFocus}
                    setSpotInFocus={setSpotInFocus} />
            </div>
        </>
    );
}