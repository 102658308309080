import { useEffect, useState } from "react";
import { BsFillExclamationCircleFill, BsXLg } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import { auth, deleteAccount } from "../authHandler";
import LoadingAnimation from "../components/LoadingAnimation";

export default function DeleteAccount() {
    const [accountDeleted, setAccountDeleted] = useState<string>("");
    const [email, setEmail] = useState<string|null>(null);
    const [isLoading, setIsLoading] = useState(false);
    
    const navigate = useNavigate();
    
    // [] at the end leads to only one execution of useEffect in the beginning. 
    // Through this, user gets not navigated to main page when account is deleted, such that success message can be displayed.
    useEffect(() => { 
        const user = auth.currentUser;
        if (user) {
            setEmail(user.email);
        } else {
            navigate("/"); // If no user is logged in when getting to this page, navigate to main page.
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function ErrorBox() {
        return(
            <div className="errorBox">
                <BsFillExclamationCircleFill className="menuBoxIcon" />
                <span style={{marginLeft: "5px"}}>Caution: proceeding will delete your account irrecoverably! Have you read all of the above?</span>
            </div>
        );
    }

    function onDelete() {
        setIsLoading(true);
        deleteAccount().then(msg => {
            if (msg === "success") {
                setAccountDeleted("Your account has been successfully deleted. You can close this window now.");
            } else {
                setAccountDeleted(msg);
            }
            setIsLoading(false);
        });        
    }
    
    return(
        <div className="formBox">
            <div className="closeFormBox">
                <Link to="/"><BsXLg className='closeCross' /></Link>
            </div>
            <br />
            <h3>Are you sure you want to delete your Sunsetspots.ch account?</h3>
            <p>This will delete your account and related personal information for "{email}" immediately. Note that the spots you have added to Sunsetspots.ch will remain in the network.</p>
            <p>IMPORTANT: It is only possible to delete spots for existing users. Do not delete your account if your spot(s) should be removed and reach out to <a href="mailto:contact.sunsetspots@gmail.com">contact.sunsetspots@gmail.com</a>.</p>
            <ErrorBox />
            <div style={{display: "flex"}}>
                <button className="textButton" onClick={onDelete}>Delete Account</button>
                {isLoading && <LoadingAnimation />}
            </div>
            <p>{accountDeleted}</p>
        </div>
    );
}