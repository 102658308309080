import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

// Tutorial CRUD-Application with firebase: https://www.youtube.com/watch?v=jCY6DH8F4oc

// Config file for my online firebase project:
const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: "sunsetspots.ch",
  projectId: "sunset-spots",
  storageBucket: "sunset-spots.appspot.com",
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_APPID,
  measurementId: process.env.REACT_APP_MEASUREMENTID
};

// Initialize Firebase application:
export const app = initializeApp(firebaseConfig);
// Initialize Analytics:
export const analytics = getAnalytics(app);
