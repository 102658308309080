import { Link } from "react-router-dom";
import Logo  from "../assets/logo_512px.png";

export default function About() {
    return(
        <div className="textBox">
            <div style={{textAlign: "center"}}>
                <img src={Logo} className="logo" alt="Logo of Sunsetspots.ch" />
                <p className="h3ReducedMargin">2023 © Sunsetspots.ch</p>
            </div>

            <h3>About this project</h3>
            <p>Sunsetspots.ch was created as a free time project by a university student. As a romantic always searching for the perfect location to enjoy a sunset - especially when travelling - I always missed having a tool helping me to find the perfect sunset location. I hope this app is useful for you.</p>
            <p>Note that this app is still work in progress and further features might be added in the future. If you face a bug or want to suggest further features, please do so <a href="https://github.com/cedricmerz/sunsetspots-issues/issues" target="_blank" rel="noreferrer">here.</a></p>
            
            {/* https://termly.io/resources/articles/legal-requirements-for-websites/ */}
            <h3>Privacy Policy & Terms and Conditions</h3>
            <ul style={{listStyleType: "disc", marginLeft: 20}}>
                <li>Account creation and authentication on Sunsetspots.ch is needed when a user wants to contribute a spot to decrease the likelihood of spam and misuse.</li>
                <li>Sunsetspots.ch will never share your personal data or sell it to third parties.</li>
                <li>If you add a spot to Sunsetspots.ch, you give Sunsetspots.ch the right to display all information provided (e.g., image, location) to all other users of Sunsetspots.ch unbound in time.</li>
                <li>Users can request at any time to delete their account and personal data. All data about spots (including images) provided to Sunsetspots.ch remain in the database, but all personal data and the account is irrevocably removed.</li>
            </ul>
            <br/>
            <ul style={{listStyleType: "disc", marginLeft: 20}}>
                <li>Sunsetspots.ch cannot guarantee that all spots available on the map really exist and really offer a beautiful view of the sunset.</li>
            </ul>

            <h3>Used technologies</h3>
            <ul style={{listStyleType: "disc", marginLeft: 20}}>
                <li>To authenticate and authorize users, Sunsetspots.ch uses <a href="https://firebase.google.com/docs/auth" target="_blank" rel="noreferrer">Firebase Authentication by Google</a>. Firebase leverages modern industry standards and is considered safe. Sunsetspots.ch cannot see your password at any time. Your mail address is never visible to other users.</li>
                <li>All data (including user accounts, spots information, and hosting of the website) is stored on Google Cloud infrastructure in Zürich, Switzerland (europe-west6). You find more information <a href="https://firebase.google.com/docs/projects/locations" target="_blank" rel="noreferrer">here</a>.</li>
                <li>Google Analytics is used to track usage data of this app. This might include your browser type, device type, estimated location, and pages visited. This data cannot be assigned to a specific user.</li>
            </ul>

            <h3>Contact</h3>
            <p>If you have questions or want to reach out, please find more information <Link to="/contact">here</Link>. Last update of about page: 16.04.2023</p>
            <br />


            
        </div>
    );
}