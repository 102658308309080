import { Link } from "react-router-dom";

export default function Contact() {
    return(
        <div className="textBox">
           <h3>Contact Information</h3>
           <p>Hi there! Glad to hear that you want to reach out. If you have questions regarding Sunsetspots.ch please make sure to check the <Link to="/about">"About" page</Link> first.</p>
           <p>If you want to report bugs or suggest features, please do so <a href="https://github.com/cedricmerz/sunsetspots-issues/issues" target="_blank" rel="noreferrer">here</a>.</p>
           <p>For all other questions or topics, you may contact us by writing an email to <a href="mailto:contact.sunsetspots@gmail.com">contact.sunsetspots@gmail.com</a>.</p>
        </div>
    );
}