import { User } from "firebase/auth";
import { latLng, LatLng } from "leaflet";
import { useEffect, useState } from "react";
import { BsFillExclamationCircleFill, BsXLg } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import { addSpotToDb, spotObj, uploadImage } from "../dbHandler";
import LoadingAnimation from "./LoadingAnimation";
import imageCompression from "browser-image-compression";
import { Timestamp } from "firebase/firestore";
import { SpotCategory } from "../types/SpotCategory";
import SpotCategorySelection from "./SpotCategorySelection";

interface IProps {
    setIsAddingSpot: React.Dispatch<React.SetStateAction<boolean>>;
    user: User | null;
    mailVerified: boolean;
    toggleClickResultsInMarker: React.Dispatch<React.SetStateAction<boolean>>;
    addNewSpotCoord: React.Dispatch<React.SetStateAction<LatLng>>;
    newSpotCoord: LatLng;
}

/** Handles whole flow regarding adding a spot.
 * 1. step: Place marker on map.
 * 2. step: Enter details about spot. */
export default function AddSpotFlow({setIsAddingSpot, user, mailVerified, toggleClickResultsInMarker, addNewSpotCoord, newSpotCoord}: IProps) {

    const navigate = useNavigate();
    const[entryFormPos, setEntryFormPos] = useState(1);
    const[spotTitle, setSpotTitle] = useState<string>("");
    const[spotDescription, setSpotDescription] = useState<string>("");
    const[spotCategories, setSpotCategories] = useState<SpotCategory[]>([]);
    const[image, setImage] = useState<File | undefined>();
    const[isLoading, setIsLoading] = useState(false);
    const[errMsg, setErrMsg] = useState<string>();

    useEffect(() => {
        if(user === null) { // if no user is logged in -> cancel process and navigate to login page.
            cancel();
            navigate("/login");
        }
        else if(!mailVerified) { // if mail address of user not verified -> navigate to verification page.
            cancel();
            navigate("/verify-mail");
        }
    });

    if(entryFormPos === 1) { // enable adding markers in the first step of flow.
        toggleClickResultsInMarker(true);
    }

    /** may get called at any time during spot adding. Ends the process, sets back state of app related to adding and closes window. */
    function cancel(): void {
        toggleClickResultsInMarker(false);
        addNewSpotCoord(latLng(0,0)); // deleting user marker.
        setIsAddingSpot(false); // tell app to not render AddSpotFlow anymore.
    }

    function continueToForm(): void {
        if(!newSpotCoord.equals(latLng(0,0))) {
            setEntryFormPos(2);
            toggleClickResultsInMarker(false);
            setErrMsg(undefined);
        } else {
            setErrMsg("You must choose a location on the map to proceed.");
        }
    }

    function persistOnDb(): void {
        if (spotTitle !== "" && !newSpotCoord.equals(latLng(0,0)) && user) { // only continue if title and position on map is clicked.
            const newSpot: spotObj = {
                title: spotTitle,
                description: spotDescription,
                categories: spotCategories,
                lat: newSpotCoord.lat,
                lng: newSpotCoord.lng,
                userId: user.uid,
                timestamp: Timestamp.now()
            };
            // First: Add the spot to the db. When this is done, id of spot is returned. if an image is provided, upload image named with spot id.
            setIsLoading(true);
            addSpotToDb(newSpot).then(spotId => {
                    if(image) {
                        uploadImage(spotId, image).then(whenDone => {
                            setIsLoading(false);
                            cancel();
                        });
                    } else {
                        setIsLoading(false);
                        cancel();
                    }
                }
            );

        } else {
            setErrMsg("Oops, something went wrong. Have you set a title?");
        }
    }

    function handleCategoryClick(clickedCat: SpotCategory) {
      const isCurrentlySelected = spotCategories.some(
        (cat) => cat === clickedCat
      );
      if (isCurrentlySelected) {
        const updatedCategories = spotCategories.filter(
          (cat) => cat !== clickedCat
        );
        setSpotCategories([...updatedCategories]);
      } else {
        const updatedCategories = spotCategories;
        updatedCategories.push(clickedCat);
        setSpotCategories([...updatedCategories]);
      }
    }

    /** Component used as first step (setting marker). */
    function ChooseMarkerLocation() {
        return(
            <div>
                <p>Tap on the map where you want to add your sunsetspot until you are happy with your choice.</p>
                <div>
                    <button className="textButton" onClick={continueToForm}>Confirm location and continue</button>
                </div>
                {errMsg &&
                    <div className="errorBox">
                        <BsFillExclamationCircleFill className="menuBoxIcon" />
                        <span style={{marginLeft: "5px"}}>{errMsg}</span>
                    </div>
                }
            </div>
        );
    }

    /** Component handles upload of an image. */
    function ImageUpload() {
        /** function stores the image selected by the user to react state */
        async function fileSelect(event: React.ChangeEvent<HTMLInputElement>) {
            const fileList = event.target.files;
            if (fileList && fileList.length > 0) {

                const options = { // configuration for image compression https://www.npmjs.com/package/browser-image-compression
                    maxSizeMB: 0.15,
                    maxWidthOrHeight: 800,
                }

                try {
                    const compressedFile = await imageCompression(fileList[0], options);
                    setImage(compressedFile); // save compressed file in state of react app.
                } catch (error) {
                    console.log(error);
                }
            }
        }
        return(
            <div>
                <p className="h3ReducedMargin">If you can, please add a sunset photo from the location:</p>
                <input type="file" id="fileInput" accept="image/jpg, image/png, image/jpeg" onChange={fileSelect} style={{display: "none"}} />
                <label htmlFor="fileInput" className="textButton">Choose a picture</label>
                {image && <p className="h3ReducedMargin">{image.name}</p>}
            </div>
        );
    }

    return(
        <div className="formBox addSpotFlow">
            <div className="closeFormBox">
                <BsXLg className='closeCross' onClick={cancel} />
            </div>
            <h3 className="h3ReducedMargin">Add a new spot to sunsetspots.ch</h3>
            {entryFormPos === 1 && <ChooseMarkerLocation />}
            {entryFormPos === 2 &&
                <div>
                    <br />
                    <p className="h3ReducedMargin">Please name your spot:</p>
                    <div style={{display: "flex"}}>
                        <input type="string" placeholder="Name of Spot" className="textInput" value={spotTitle} onChange={(event) => {setSpotTitle(event.target.value)}} />
                    </div>
                    <p className="h3ReducedMargin">Spot description (optional):</p>
                    <div style={{display: "flex"}}>
                        <input type="string" placeholder="Description" className="textInput" value={spotDescription} onChange={(event) => {setSpotDescription(event.target.value)}} />
                    </div>
                    <p className="h3ReducedMargin">Select category (optional):</p>
                    <SpotCategorySelection
                      selectedCategories={spotCategories}
                      onCategoryClick={handleCategoryClick}
                      isEditable={true}
                    />
                    <br /><br />
                    <ImageUpload />
                    <br />

                    <p className="h3ReducedMargin">Note: you might need to refresh the page after submission such that the spot becomes visible.</p>
                    <div style={{display: "flex"}}>
                        <button className="textButton" onClick={persistOnDb}>Submit Spot</button>
                        {isLoading && <LoadingAnimation />}
                    </div>
                    <p className="h3ReducedMargin" style={{fontStyle: "italic"}}>With "submit" you accept <Link to="/about">terms and conditions</Link>.</p>

                    {errMsg &&
                        <div className="errorBox">
                            <BsFillExclamationCircleFill className="menuBoxIcon" />
                            <span style={{marginLeft: "5px"}}>{errMsg}</span>
                        </div>
                    }
                </div>
            }
        </div>
    );
}
