import { onAuthStateChanged } from "firebase/auth";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { BsFillExclamationCircleFill, BsXLg } from "react-icons/bs";

import { auth, logInWithEmailAndPassword, signInWithGoogle } from "../authHandler";
import LoadingAnimation from "../components/LoadingAnimation";
import PasswordInput from "../components/PasswordInput";

export default function Login() {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [errMsg, setErrMsg] = useState<string>();
  const navigate = useNavigate();

  // when the user is successfully logged in, we navigate back to main page:
  onAuthStateChanged(auth, (user) => {
    if (user) navigate("/");
  });

  function login(): void {
    setIsLoading(true);
    logInWithEmailAndPassword(email, password).then((newErr) => {
      if (newErr !== "success") {
        setErrMsg(newErr);
        setIsLoading(false);
      }
    });
  }
  function loginWithGoogle(): void {
      setIsLoading(true);
      signInWithGoogle().then((newErr) => {
          if (newErr !== "success") {
            setErrMsg(newErr);
            setIsLoading(false);
          }
      });
  }

  function ErrorBox() {
    return (
      <div className="errorBox">
        <BsFillExclamationCircleFill className="menuBoxIcon" />
        <span style={{ marginLeft: "5px" }}>{errMsg}</span>
      </div>
    );
  }

  return (
    <div className="formBox">
      <div className="closeFormBox">
        <Link to="/">
          <BsXLg className="closeCross" />
        </Link>
      </div>
      <br />
      <h3>Please sign in to contribute to sunsetspots!</h3>
      <div style={{ display: "flex" }}>
        <input
            type="email"
            className="textInput"
            onChange={(e) => setEmail(e.target.value)}
            placeholder="E-Mail Address"
        />
      </div>
      <PasswordInput onChange={(e) => setPassword(e.target.value)} />
      {errMsg && <ErrorBox />}
      <br />
      <p>
        Forgot your password? <Link to="/reset-password">Reset it here!</Link>
      </p>
      <div style={{ display: "flex" }}>
        <button className="textButton" onClick={login}>
          Login
        </button>
        <button className="textButton" onClick={loginWithGoogle}>
          Sign in with Google
        </button>
        <div className="login-animation">
        {isLoading && <LoadingAnimation />}
        </div>
      </div>
      <p>
        Don't have an account? <Link to="/create-account">Create one!</Link>
      </p>
    </div>
  );
}
