import { LatLng } from 'leaflet';
import { useState } from 'react';
import { MapContainer, TileLayer } from 'react-leaflet';
import Control from 'react-leaflet-custom-control';
import LocationButton from './mapComponents/LocationButton';
import MarkerAdded from './mapComponents/MarkerAdded';

import Markers from './mapComponents/Markers'
import SearchBar from './mapComponents/SearchBar';

// Installation of map: https://blog.logrocket.com/react-leaflet-tutorial/
// Documentation of react-leaflet: https://react-leaflet.js.org/

interface IProps {
    clicksResultInMarker: boolean;
    addNewSpotCoord: React.Dispatch<React.SetStateAction<LatLng>>;
    newSpotCoord: LatLng;
    setSpotInFocus: React.Dispatch<React.SetStateAction<string | undefined>>;
    spotInFocus: string | undefined;
}

export default function Map( {clicksResultInMarker, addNewSpotCoord, newSpotCoord, spotInFocus, setSpotInFocus}: IProps ) {
    const [currentLocation, setCurrentLocation] = useState(() => {
        return {lat: 47.1418, lng: 8.4536};
    });

    return(
        <MapContainer center={currentLocation} zoom={8} scrollWheelZoom={true}>
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" 
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a>'/>
            <SearchBar />
            <Control prepend position='topleft'>
                <LocationButton setCurrentLocation={setCurrentLocation} />
            </Control>
            <Markers
                spotInFocus={spotInFocus}
                setSpotInFocus={setSpotInFocus} />
            <MarkerAdded 
                clicksResultInMarker={clicksResultInMarker} 
                addNewSpotCoord={addNewSpotCoord}
                newSpotCoord={newSpotCoord} />
        </MapContainer>
    );
}