import * as React from 'react';
import { GiHamburgerMenu } from 'react-icons/gi';
import { BsXLg } from 'react-icons/bs';
import { CgProfile } from 'react-icons/cg';
import { Link, useNavigate } from 'react-router-dom';
import { User } from 'firebase/auth';

interface IProps {
    displayNavigation: boolean;
    toggleDisplayNavigation: React.Dispatch<React.SetStateAction<boolean>>;
    user: User | null;
}

export default function MenuBar(
    {
        displayNavigation,
        toggleDisplayNavigation,
        user
    }: IProps) {
    
    const navigate = useNavigate();

    /** function toggles the navigation menu. */
    function setDisplayNaviation(): void {
        toggleDisplayNavigation(!displayNavigation);
    }

    function onLogin(): void {
        toggleDisplayNavigation(false);
        navigate("/login");
    }

    function onProfile(): void {
        toggleDisplayNavigation(false);
        navigate("/user-profile");
    }

    function navigateHome(): void {
        toggleDisplayNavigation(false);
        navigate("/"); // some redundancy here, as already done with link. However, "Link" makes it more userfriendly (displaying link).
    }

    function LoginButton() {
        return(
            <button className="textButton" onClick={onLogin}>Login</button>            
        );
    }

    function ProfileButton() {
        return(
            <button className="iconButton" onClick={onProfile}>
                <CgProfile className='navBarIcon' />
            </button>
        );
    }

    return(
        <div className="menuBar">
            <div className="leftArea">
                <button className='iconButton' onClick={setDisplayNaviation}>
                    {displayNavigation ? <BsXLg className='navBarIcon' /> : <GiHamburgerMenu className='navBarIcon'/> }
                </button>
            </div>
            <div className="middleArea">
                <Link to="/" className="pageTitleLink">   
                    <h1 className='pageTitle' onClick={navigateHome}>Sunsetspots.ch</h1>
                </Link>
            </div>
            <div className="rightArea">
                {!user ? <LoginButton /> : <ProfileButton /> }
            </div>     
        </div>
    );
}