import { useEffect, useState } from "react";
import { BsXLg, BsSignpost, BsExclamationCircle, BsFillExclamationCircleFill } from "react-icons/bs";
import { addReportedSpotToDb, getImageUrlById, getSpotInfoById } from "../dbHandler";
import LoadingAnimation from "./LoadingAnimation";
import SpotCategorySelection from './SpotCategorySelection';
import { SpotCategory } from "../types/SpotCategory";

interface IProps {
    spotInFocus: string;
    setSpotInFocus: React.Dispatch<React.SetStateAction<string | undefined>>
}

export default function SpotInfoBox({spotInFocus, setSpotInFocus}: IProps) {
    const[title, setTitle] = useState<string>();
    const[description, setDescription] = useState<string>();
    const[categories, setCategories] = useState<SpotCategory[]>([]);
    const[hasImage, setHasImage] = useState<boolean | undefined>(); // undefined if unknown
    const[imageUrl, setImageUrl] = useState<string>();
    const[imageLoaded, setImageLoaded] = useState<boolean>(false);
    const[link, setLink] = useState<string>();
    const[isReporting, setIsReporting] = useState<boolean>(false);
    const[reported, setReported] = useState<boolean>(false);

    function close(): void {
        setSpotInFocus(undefined); // this tells "Home.tsx" to not render the SpotInfoBox anymore.
    }

    useEffect(() => { // when spotInFocus changes, data is fetched from db about spotInFocus.
        setTitle(undefined);
        setLink(undefined);
        setImageUrl(undefined);
        setImageLoaded(false);
        setReported(false);

        getSpotInfoById(spotInFocus).then(spotInfo => {
            setTitle(spotInfo.title);
            setDescription(spotInfo.description);
            setCategories(spotInfo.categories);
            const googleUrl = "https://www.google.com/maps/dir/?api=1&destination=" + spotInfo.lat + "," + spotInfo.lng;
            setLink(googleUrl);
        });
        getImageUrlById(spotInFocus).then(url => {
            if (url) {
                setImageUrl(url)
                setHasImage(true)
            } else {
                setHasImage(false)
            }
        });
    }, [spotInFocus]);

    function toGoogleNavigation() {
        window.open(link, '_blank');
    }

    function openReportWindow() {
        setIsReporting(true);
    }

    function ReportSpot() {
        const[reportReason, setReportReason] = useState<string>();
        const[errMsg, setErrMsg] = useState<string>();

        function closeReportWindow() {
            setIsReporting(false);
        }

        function ErrorBox() {
            return(
                <div className="errorBox">
                    <BsFillExclamationCircleFill className="menuBoxIcon" />
                    <span style={{marginLeft: "5px"}}>{errMsg}</span>
                </div>
            );
        }
        
        function commitReport() {
            if(!reportReason) {
                setErrMsg("Please explain why you report this spot.")
            } else {
                addReportedSpotToDb(spotInFocus, reportReason);
                setReported(true);
                closeReportWindow();
            } 
        }
        
        return(
            <div className="formBox reportSpot">
                <div className="closeFormBox">
                    <BsXLg className='closeCross' onClick={closeReportWindow} />
                </div>
                <h3 className="h3ReducedMargin">Do you want to report "{title}"?</h3>
                <p>Thank you for helping to keep Sunsetspots.ch a safe and friendly environment. Please tell us why you are reporting this spot or image:</p>
                <input type="text" className="textInput" onChange={(e) => setReportReason(e.target.value)} placeholder = "Your reason for reporting" />
                <br /><br />
                <button className="textButton" onClick={commitReport}>Report spot</button>
                {errMsg && (<ErrorBox />)}
            </div>
        );
    }

    return(
        <>
        {isReporting && (<ReportSpot />)}
        {hasImage !== undefined ?
            <div className="formBox addSpotFlow" style={title ? {opacity: 1} : {opacity: 0}}>
                <div className="closeFormBox">
                    <BsXLg className='closeCross' onClick={close} />
                </div>
                <h3 className="h3ReducedMargin">{title}</h3>
                {hasImage && !imageLoaded && <div className="spotImageLoader"><LoadingAnimation /></div>}

                {hasImage && imageUrl && <img src={imageUrl} onLoad={() => setImageLoaded(true)} style={!imageLoaded ? {display: 'none'} : {}}
                    className="spotImage" alt="Choice of spot creator: probably a sunset." />}
                
                {description ?
                  <div className="spotInfoDescriptionContainer">
                    <span>{description}</span>
                  </div> : null}
                  <SpotCategorySelection selectedCategories={categories} isEditable={false} onCategoryClick={() => {}}/>

                <div className="menuBox">
                    <ul>
                        <li>
                            <button className="menuButton" onClick={toGoogleNavigation}>
                                <BsSignpost className="menuBoxIcon" />
                                <span className="menuBoxText">Start navigation on Google Maps</span>
                            </button>
                        </li>
                        <li>
                            <button className="menuButton" onClick={openReportWindow}>
                                <BsExclamationCircle className="menuBoxIcon" />
                                <span className="menuBoxText">Report this spot or image</span>
                            </button>
                        </li>
                    </ul>
                </div>
                {reported && <p>Thank you for reporting this spot. We will soon have a look at it.</p>}
            </div> 
            : null 
        }
        </>
    );
}
