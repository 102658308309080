import { BsFillCloudSunFill } from "react-icons/bs";
import { Link } from "react-router-dom";


export default function EmptyPage() {
    return (
        <div className="errorPage">
            <br /><br />
            <BsFillCloudSunFill className="profilePicture" />
            <h3>Oops, something went wrong...</h3>
            <Link to="/">
                <button className="textButton">Back to the main page</button>
            </Link>
        </div>
    );
}