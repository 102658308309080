import React, { useState } from "react";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";

export interface InputProps extends React.ComponentPropsWithRef<"input"> {}

export default function PasswordInput(props: InputProps) {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  function togglePasswordVisibility() {
    const updatedVisibility = !isPasswordVisible;
    setIsPasswordVisible(updatedVisibility);
  }

  return (
    <div className={"passwordContainer"}>
      <input
        type={isPasswordVisible ? "text" : "password"}
        className={`textInput ${props.className}`}
        onChange={props.onChange}
        placeholder={props.placeholder || "Password"}
      />
      <div
        className="passwordVisibilityIcon"
        onClick={() => togglePasswordVisibility()}
      >
        {isPasswordVisible ? <BsFillEyeSlashFill /> : <BsFillEyeFill />}
      </div>
    </div>
  );
}
