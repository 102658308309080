import { useState } from "react";
import { BsXLg } from "react-icons/bs";
import { Link } from "react-router-dom";
import { resetPassword } from "../authHandler";

export default function ResetPassword() {
    const [email, setEmail] = useState<string>("");
    const [mailSent, setMailSent] = useState<string>();

    function onResetPassword(): void {
        setMailSent("A password reset mail was sent to your mailbox. Make sure to look in your spam folder if you cannot find it. You can close this window now.")
        resetPassword(email);
    }

    return(
        <div className="formBox">
            <div className="closeFormBox">
                <Link to="/"><BsXLg className='closeCross' /></Link>
            </div>
            <br />
            <h3>You want to reset your password for Sunsetspots.ch?</h3>
            <p>Please enter the e-mail address of your account here. We will send you an email with a link to set it back.</p>
            <div style={{ display: "flex" }}>
                <input type="email" className="textInput" onChange={(e) => setEmail(e.target.value)} placeholder = "E-Mail Address" />
            </div>
            <br />
            <button className="textButton" onClick={onResetPassword}>Reset password</button>
            <p>{mailSent}</p>
        </div>
    );
}