import { latLng, LatLng, Icon } from 'leaflet';
import { Fragment, useEffect, useState } from 'react';
import { Marker } from 'react-leaflet';
import { getSpotsFromDb } from '../../dbHandler';
import OrangeMarker from "../../assets/orange_marker.png"
import RedMarker from "../../assets/red_marker.png"
import MarkerShadow from "../../assets/shadow_for_marker.png"

/** Type specifies needed values for marker creation on leaflet map. */
type markerObj = {
    id: string;
    coordinates: LatLng;
}

interface IProps {
    setSpotInFocus: React.Dispatch<React.SetStateAction<string | undefined>>;
    spotInFocus: string | undefined;
}

/** Component loads all markers from db and displays them on map. */
export default function Markers({spotInFocus, setSpotInFocus}: IProps) {
        
    const [markers, addMarker] = useState<markerObj[]>([]);

    // Marker and shadow downloaded from here: https://github.com/pointhi/leaflet-color-markers
    const orangeIcon = new Icon({
        iconUrl: OrangeMarker,
        shadowUrl: MarkerShadow,
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41]
    });
    const redIcon = new Icon({
        iconUrl: RedMarker,
        shadowUrl: MarkerShadow,
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41]
    });

    // Request spots from db and display them:
    useEffect(() => { // useEffect-hook is executed once when the page is loaded.
        getSpotsFromDb().then(allSpots => { // getSpotsFromDb returns Promise (because async), therefore we need ".then".
            allSpots.forEach(spot => {
                const spotCoordinates: LatLng = latLng(spot.lat, spot.lng);
                const newMarker: markerObj = {id: spot.id, coordinates: spotCoordinates};
                addMarker((prevValue) => [...prevValue, newMarker]); // add markers from DB to map.
            });
        });
    }, []);
    
    return (
        <Fragment>
            {markers.map(marker => 
                <Marker key={marker.id} position={marker.coordinates} icon={marker.id == spotInFocus ? redIcon : orangeIcon} eventHandlers={{
                    click: () => {setSpotInFocus(marker.id)}
                }}></Marker>)}
        </Fragment>
    )
}