import { BiCurrentLocation } from 'react-icons/bi'
import { Dispatch, SetStateAction } from 'react';
import { useMap } from 'react-leaflet';

// using hooks and interface in typescript: https://javascript.plainenglish.io/how-to-use-the-usestate-hook-in-react-with-typescript-4463b0b4051f

interface IProps {
    setCurrentLocation: Dispatch<SetStateAction<{lat: number; lng: number;}>>;
}

/** Component adds button to locate user to map and handles functionality thereof. */
export default function LocationButton({setCurrentLocation}: IProps) {
    const map = useMap()

    /** Gets called when user clicks on location button */
    function setLocation() {
        navigator.geolocation.getCurrentPosition(
            function(position) {
                let userLocation = {lat: position.coords.latitude, lng: position.coords.longitude}
                
                setCurrentLocation(userLocation);
                map.setView(userLocation, 14);
            }
        )
    }

    return (
        <button className="locButton" onClick={setLocation}>
            <BiCurrentLocation  className="locationIcon" />
        </button>
    );
}