import { Icon, latLng, LatLng } from "leaflet";
import React from "react";
import { Marker, useMapEvents } from "react-leaflet";
import RedMarker from "../../assets/red_marker.png"
import MarkerShadow from "../../assets/shadow_for_marker.png"

interface IProps {
    clicksResultInMarker: boolean;
    addNewSpotCoord: React.Dispatch<React.SetStateAction<LatLng>>;
    newSpotCoord: LatLng;
}

/** Component handles user input when a new marker should be added to the map. https://stackoverflow.com/questions/42835692/react-leaflet-add-markers-dynamically */
export default function MarkerAdded({clicksResultInMarker, addNewSpotCoord, newSpotCoord}: IProps){    
    
    // add marker to the location where map is clicked (but only if adding markers is activated):
    useMapEvents({
        click(e) {
            if(clicksResultInMarker) { 
                addNewSpotCoord(e.latlng); // save in app state such that AddSpotFlow can use it.
            }
        }
    });

    // Marker and shadow downloaded from here: https://github.com/pointhi/leaflet-color-markers
    const redIcon = new Icon({
        iconUrl: RedMarker,
        shadowUrl: MarkerShadow,
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41]
    });

    if(newSpotCoord.equals(latLng(0,0))) { return ( <React.Fragment />)}

    return(
        <React.Fragment>
            <Marker position={newSpotCoord} icon={redIcon}></Marker>
        </React.Fragment>
    );
}