import { useNavigate } from "react-router-dom";
import { CgProfile } from 'react-icons/cg';
import { useEffect } from "react";
import { User } from "firebase/auth";
import { logOut } from "../authHandler";
import { BsFillPersonXFill } from "react-icons/bs";
import { MdPassword } from "react-icons/md";

interface IProps{
    user: User | null;
}

export default function UserProfile({user}: IProps) {
    const navigate = useNavigate();

    useEffect(() => {
        if(!user) { navigate("/login"); }
    });

    function changePassword(): void {
        navigate("/reset-password");
    }

    function deleteAccount(): void {
        navigate("/delete-account");
    }
    
    return(
        <div>
            <div className="profileBox">
                <CgProfile className="profilePicture" />
                <h3>Hi, {user?.email}</h3>
                <button className="textButton" onClick={() => logOut()}>Logout</button>
            </div>
            <div className="menuBox">
                <ul>
                    <li>
                        <button onClick={changePassword} className="menuButton">
                            <MdPassword className="menuBoxIcon" />
                            <span className="menuBoxText">Change your password</span>
                        </button>
                    </li>
                    <li>
                        <button onClick={deleteAccount} className="menuButton">
                            <BsFillPersonXFill className="menuBoxIcon" />
                            <span className="menuBoxText">Delete your account</span>
                        </button>
                    </li>
                </ul>         
            </div>
        </div>
    );
}