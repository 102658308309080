import { onAuthStateChanged } from "firebase/auth";
import { useState } from "react";
import { BsFillExclamationCircleFill, BsXLg } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";

import { auth, registerWithEmailAndPassword } from "../authHandler";
import LoadingAnimation from "../components/LoadingAnimation";
import PasswordInput from "../components/PasswordInput";

export default function CreateAccount() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errMsg, setErrMsg] = useState<string>();

  const navigate = useNavigate();

  // when the user is successfully logged in, we navigate to the e-mail verification page.
  onAuthStateChanged(auth, (user) => {
    if (user) navigate("/verify-mail");
  });

  function register(): void {
    setIsLoading(true);
    if (!email || !password) {
      setErrMsg("Provide an email address and a password.");
      setIsLoading(false);
    } else {
      registerWithEmailAndPassword(email, password).then((newErr) => {
        if (newErr !== "success") {
          setErrMsg(newErr);
          setIsLoading(false);
        }
      });
    }
  }

  function ErrorBox() {
    return (
      <div className="errorBox">
        <BsFillExclamationCircleFill className="menuBoxIcon" />
        <span style={{ marginLeft: "5px" }}>{errMsg}</span>
      </div>
    );
  }

  return (
    <div className="formBox">
      <div className="closeFormBox">
        <Link to="/">
          <BsXLg className="closeCross" />
        </Link>
      </div>
      <br />
      <h3>Create a new account for Sunsetspots.ch</h3>
      <p>
        Note: You will need to verify your email address in the next step to be
        able to contribute.
      </p>
      <div style={{ display: "flex" }}>
        <input
          type="email"
          className="textInput"
          onChange={(e) => setEmail(e.target.value)}
          placeholder="E-Mail Address"
        />
      </div>
      <PasswordInput onChange={(e) => setPassword(e.target.value)} />
      {errMsg && <ErrorBox />}
      <div style={{ display: "flex" }}>
        <button className="textButton" onClick={register}>
          Create Account
        </button>
        {isLoading && <LoadingAnimation />}
      </div>
      <p>
        Sunsetspots.ch will never share your data or sell it to third parties,
        and follows high data protection standards. Read more about it{" "}
        <Link to="/about">here</Link>.
      </p>
    </div>
  );
}
